<template>
  <section class="px-8" v-if="blockActive">
    <div class="text-lg font-bold">Data & Options</div>
    <label class="my-3 flex-col items-center text-sm">
      <div class="w-32 shrink-0">Data point</div>
      <input
        type="text"
        class="w-82 rounded bg-neutral-600 p-1 [font-size:inherit] focus:outline-none focus-visible:ring-1"
        list="datapoints"
        v-model="blockActive.data"
      />
      <datalist id="datapoints">
        <option></option>
        <template v-for="(v1, k1) in dataReport">
          <option v-for="(v2, k2) in v1">{{ k1 }}.{{ k2 }}</option>
        </template>
      </datalist>
    </label>
    <label class="my-3 flex-col items-center text-sm">
      <div class="mt-2 w-32 shrink-0">Visualisation type</div>
      <select
        class="w-82 rounded bg-neutral-600 p-1 [font-size:inherit] focus:outline-none focus-visible:ring-1"
        :value="blockActive.component"
        @input="setComponent"
      >
        <template v-if="!blockActive.component || blockActive.component.startsWith('page')">
          <option></option>
          <optgroup label="Page">
            <option :value="component" v-for="component in componentList.filter(k => k.startsWith('page'))">
              {{ component.slice(5) }}
            </option>
          </optgroup>
        </template>
        <template v-else>
          <option value="block"></option>
          <option :value="component" v-for="component in componentList.filter(k => baseComponents.includes(k))">
            {{ component }}
          </option>
          <optgroup label="Custom" v-if="blockActive.component && !blockActive.component.startsWith('page')">
            <option
              :value="component"
              v-for="component in componentList.filter(
                k => !baseComponents.includes(k) && !k.startsWith('page') && !['block'].includes(k),
              )"
            >
              {{ component }}
            </option>
          </optgroup>
        </template>
      </select>
    </label>
    <!-- <div>{{ blockComponent?.api }}</div> -->
    <builderui-api-options
      v-for="(api, name) in apiOptions"
      :api="api"
      :name="name"
      :block-report="blockReport"
      :context="context"
      v-model="blockActive.options"
      @reset="(ev: string) => resetOption(ev)"
      :key="name"
      class="my-3"
    ></builderui-api-options>
  </section>
  <section class="border-t border-gray-500 px-8 pt-8" v-if="blockActive?.options">
    <builderui-translation-input v-model="blockActive.options.title" label="Title" :context="context" />
    <builderui-translation-input v-model="blockActive.options.subtitle" label="Subtitle" :context="context" />
    <builderui-translation-input v-model="blockActive.options.disclaimer" label="Disclaimer" :context="context" />
    <builderui-footnote-section
      :path="footnotePath"
      v-model="template"
      :store="store"
      :component="blockActive.component"
      :context="context"
      @active="emit('active', $event)"
    ></builderui-footnote-section>
  </section>
  <section class="flex flex-col gap-2 border-t border-gray-500 px-8 py-8" v-if="blockStylesheetName">
    <div class="text-lg font-bold">Styles</div>
    <div class="flex flex-col">
      <div class="flex flex-row items-center justify-between">
        <div class="w-40 text-sm">Stylesheet</div>
        <button class="hover:underline" v-if="blockActive?.stylesheet" @click="resetStylesheet">
          Reset to default
        </button>
      </div>
      <select
        class="w-82 rounded bg-neutral-600 p-1 [font-size:inherit] focus:outline-none focus-visible:ring-1"
        :value="blockStylesheetName"
        @input="ev => setBlockStylesheet(ev)"
      >
        <option v-for="stylesheet in stylesheets" :key="stylesheet" :value="stylesheet">
          {{ stylesheet }}
        </option>
      </select>
    </div>
    <div class="flex flex-col">
      <div class="flex flex-row items-center justify-between">
        <div class="w-40 text-sm">Palette</div>
        <button class="hover:underline" v-if="blockActive?.palette" @click="resetPalette">Reset to default</button>
      </div>
      <select
        class="w-82 rounded bg-neutral-600 p-1 [font-size:inherit] focus:outline-none focus-visible:ring-1"
        :value="blockPaletteIndex"
        @input="ev => setBlockPalette(ev)"
      >
        <option v-for="(palette, idx) in palettes" :key="palette.name" :value="idx">
          {{ palette.name }}
        </option>
      </select>
    </div>
    <div class="text-base font-bold">Style Overrides</div>
    <div class="flex flex-col pl-2" v-for="style in blockStyles" :key="style">
      <div class="flex flex-row items-center justify-between">
        <div class="w-40 text-sm">{{ style.name || style.key.titleize() }}</div>
        <div class="flex flex-row items-center gap-2">
          <button class="hover:underline" v-if="styleOverrides?.[style.key]" @click="resetOverride(style.key)">
            Reset
          </button>
          <button class="hover:underline" @click="addStyleOverride(style.key)" v-else>Edit</button>
        </div>
      </div>
      <textarea
        class="h-28 w-full resize-none rounded-lg bg-neutral-600 p-2 text-base"
        v-model="styleOverrides[style.key].css"
        @input="ev => updateStyleOverride(ev, style.key)"
        v-if="styleOverrides?.[style.key]"
      ></textarea>
      <!-- {{ blockStyles }} -->
    </div>
  </section>
</template>
<script setup lang="ts">
import { computed, ref, watch, onMounted, nextTick } from 'vue'
import type { TemplateLayout, ComponentNode, Stylesheet } from '../../builder'
import { useComponents } from '../../composables/builderComponent'
import { getComponentData } from '../../lib/builder'
import useLayout from '../../composables/layout'
import stylesheetService from '@100-m/hauru/src/services/StylesheetService'

const props = defineProps(['componentList', 'dataReport', 'context', 'stylesheets', 'store'])
const { getComponent } = useComponents()
const baseComponents = ['text', 'table', 'line', 'bar', 'pie', 'image', 'graph-table', 'characteristics']

const emit = defineEmits(['updateStyle', 'active'])
const template = defineModel<TemplateLayout>()

const blockActive = ref<ComponentNode>()
const styleOverrides = ref<Record<string, any>>({})

// If block its the node path, if its the footer return the page, if its a footnote component return the global node
const footnotePath = computed(() => {
  // if (!props.store.active) return
  if (blockActive?.value?.component === 'footnotes') return []
  if (blockActive?.value?.component === 'footer') return [props.store.active[0]]
  return props.store.active
})

const { getNode, updateNode } = useLayout(props.store, template)

const blockData = computed(() => {
  if (!blockActive?.value?.data) return
  const componentData = getComponentData(blockActive.value.data, props.dataReport)
  return componentData?.data
})
const blockReport = computed(() => {
  const path = props.store.active
  const blockReport = blockActive.value
  // const data_component = getComponentData(blockReport.data, dataReport.value)
  return { ...blockReport, path, data_component: blockData.value, context: props.context }
})
const blockComponent = computed(() => {
  if (!blockActive.value?.component) return
  console.log('blockActive.value.component', blockActive.value.component)
  return getComponent(blockActive.value.component)
})

const apiOptions = computed(() => {
  if (!blockComponent?.value?.api) return
  return Object.fromEntries(
    Object.entries(blockComponent.value.api).filter(([k, v]) => !v.hide || !v.hide(blockReport.value)),
  )
})
let init = true
watch(
  () => props.store.active,
  () => {
    if (!props.store.active || !props.store.active.length) return
    init = true
    blockActive.value = JSON.parse(JSON.stringify(getNode(props.store.active)))
    if (blockActive.value && !blockActive.value.options) {
      blockActive.value.options = {}
    }
    nextTick(() => {
      init = false
      styleOverrides.value = JSON.parse(JSON.stringify(blockActive.value?.styleOverrides || {}))
    })
  },
  { immediate: true },
)
function debounce(func, timeout = 300) {
  let timer
  return (...args) => {
    clearTimeout(timer)
    timer = setTimeout(() => {
      func.apply(this, args)
    }, timeout)
  }
}
function _emitUpdate() {
  updateNode(props.store.active, JSON.parse(JSON.stringify(blockActive.value)))
  nextTick(() => {
    window.dispatchEvent(new CustomEvent('builderUpdate', { detail: { path: props.store.active } }))
    // Maybe only update styles when the style change not on all updates
    emit('updateStyle')
  })
  // init = true
}
const emitUpdate = debounce(_emitUpdate, 300)

function resetOption(optionName: string) {
  blockActive.value.options[optionName] = blockComponent.value?.api[optionName]?.default(blockReport.value)
}
function setComponent(ev: InputEvent) {
  const component = (ev.target as HTMLInputElement).value
  blockActive.value.options = {}
  blockActive.value.component = component
}

watch([blockData, blockComponent], () => {
  if (blockData.value && blockComponent.value?.api) {
    Object.entries(blockComponent.value.api).forEach(([k, v]) => {
      if (!v.default || Object.prototype.hasOwnProperty.call(blockActive.value.options, k)) return
      blockActive.value.options[k] = v.default({ data_component: blockData.value, ...blockActive.value })
    })
  }
})

watch(
  () => blockActive.value,
  () => {
    console.log('watcher blockActive', init)
    if (init) return
    emitUpdate()
  },
  { deep: true },
)

const blockStylesheet = ref<Stylesheet | null>(null)
const blockStylesheetName = computed(() => {
  if (!blockActive.value?.component) return
  return blockStylesheet?.value?.name || template?.value?.theme?.stylesheet
})

const palettes = computed(() => {
  if (!blockStylesheetName.value) return
  const stylesheet = template.value.theme.loadedStylesheets[blockStylesheetName.value]
  return stylesheet?.palettes
})
const blockPaletteIndex = computed(() => {
  if (!blockActive.value?.component) return
  if (!blockStylesheetName.value) return
  const stylesheet = template.value.theme.loadedStylesheets[blockStylesheetName.value]
  return '' + (blockActive.value?.palette || stylesheet.defaultPalette)
})
function setBlockPalette(ev: Event) {
  if (!ev.target) return
  const paletteIndex = (ev.target as HTMLInputElement).value
  blockActive.value.palette = paletteIndex
  emitUpdate()
}
function resetPalette() {
  blockActive.value.palette = undefined
  emitUpdate()
}

function setBlockStylesheet(ev: Event) {
  if (!ev.target) return
  const stylesheetName = (ev.target as HTMLInputElement).value
  blockActive.value.stylesheet = stylesheetName
}

watch(
  () => blockActive?.value?.stylesheet,
  async () => {
    await updateBlockStylesheet()
    if (init) return
    emitUpdate()
  },
)

onMounted(() => {
  if (!blockActive.value) {
    return
  }
  updateBlockStylesheet()
})
async function updateBlockStylesheet() {
  const stylesheetName = blockActive.value.stylesheet
  if (!stylesheetName) {
    blockStylesheet.value = null
  } else if (template?.value?.theme?.loadedStylesheets?.[stylesheetName]) {
    blockStylesheet.value = template.value.theme.loadedStylesheets[stylesheetName]
  } else {
    const stylesheetsWithName = await stylesheetService.findManyByName({ name: stylesheetName })
    const stylesheet = stylesheetsWithName?.[0]

    // @ts-expect-error loadedStylesheet is initialized and template.value is never undefined
    template.value.theme.loadedStylesheets[stylesheetName] = stylesheet
    blockStylesheet.value = stylesheet
  }
}
const blockStyles = computed(() => {
  if (!blockComponent.value || !blockComponent.value.styles) return []
  const baseStyles = [{ key: 'heading1' }, { key: 'heading2' }, { key: 'disclaimer' }, { key: 'container' }]
  const componentStyles = Object.entries(blockComponent.value.styles).map(([key, value]) => ({ key, ...value }))
  return componentStyles.concat(baseStyles)
})

function addStyleOverride(style: string) {
  if (blockStylesheet.value) {
    if (!blockStylesheet.value?.styles?.[style]) {
      styleOverrides.value[style] = { name: style, css: '' }
      return
    }
    styleOverrides.value[style] = JSON.parse(JSON.stringify(blockStylesheet.value?.styles[style]))
  } else {
    // @ts-expect-error loadedStylesheet is initialized and template.value is never undefined
    const stylesheet = template.value.theme.loadedStylesheets[blockStylesheetName.value]
    styleOverrides.value[style] = stylesheet.styles[style]
      ? JSON.parse(JSON.stringify(stylesheet.styles[style]))
      : { name: style, css: '' }
  }
}

function resetOverride(style: string) {
  delete styleOverrides.value[style]
  delete blockActive.value.styleOverrides[style]
  emitUpdate()
}
function updateStyleOverride(ev: Event, style: string) {
  if (!blockActive.value.styleOverrides) {
    blockActive.value.styleOverrides = {}
  }
  blockActive.value.styleOverrides[style] = JSON.parse(JSON.stringify(styleOverrides.value[style]))
  emitUpdate()
}
// const updateStyleOverride = _updateStyleOverride.debounce(500)

function resetStylesheet() {
  blockActive.value.stylesheet = null
  emitUpdate()
}
</script>
