<template>
  <div class="builder-footer relative flex h-fit translate-y-[26px] items-end gap-4 border-0">
    <div class="footer-bar-wrapper flex h-[60px] w-full items-center">
      <div class="footer-bar flex w-full">
        <div class="footer-filled-bar bg-secondary border-secondary h-4 w-full -skew-x-12"></div>
        <div
          class="footer-page-number border-secondary flex h-4 w-[60px] -skew-x-12 items-center justify-center border"
        >
          <div class="page-number text-secondary px-2 text-[8px] leading-[8px]">{{ pageNumber }}</div>
        </div>
      </div>
    </div>

    <div class="footer-image flex h-[60px] items-end justify-center">
      <img :src="logoImg" style="height: inherit; width: auto" alt="logo" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import usePagination from '@100-m/hauru/src/applications/builder/composables/pagination'
import { getLogoImg } from '@100-m/hauru/src/applications/builder/lib/header'
import { BuilderProps } from '@100-m/hauru/src/applications/builder/composables/builderComponent'

const props = defineProps(['data', 'options', 'context']) as BuilderProps

const { pageNumber } = usePagination(props)

const logoImg = computed(() => getLogoImg(props.context))
</script>
