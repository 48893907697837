import { reactive, watch } from 'vue'
import { INxChartOptions, VizType } from '@100-m/hauru/src/components/common/nx-chart/nx-chart'
import { ResolvedBuilderOptions } from './builderOptions'
import { formatFactory } from '../lib/format'
import { Stylesheet } from '../builder'
import useTranslations from './translations'

export interface BuilderProps {
  data?: any[] | Record<string, any>
  options: ResolvedBuilderOptions
  context: Record<string, any>
}
export interface BuilderChartOptions {
  legendOptions?: {
    position?: 'top' | 'left' | 'right' | 'bottom' | 'absolute'
    shape?: 'line' | 'square' | 'circle'
    showValues?: boolean | 'last' | 'evolution'
    orientation?: 'horizontal' | 'vertical'
    styleFn?: ({ data, options }: { data: any; options?: INxChartOptions }) => Record<string, string>
  }
}

export type BuilderChartProps = BuilderProps & {
  // Additionnal nxChartOptions
  nxChartOptions?: Partial<INxChartOptions>
  chartOptions?: BuilderChartOptions
}

interface NxChartProps {
  data?: any[]
  options: INxChartOptions
}

function getSortingFn({ x, y, sort, sortAsc }: Partial<ResolvedBuilderOptions>) {
  if (!sort) return null
  const order = sortAsc ? '' : '-'
  const sortBy = sort === 'key' ? x : y
  return order + sortBy
}
function getStylesheetPalette(stylesheet: Stylesheet, blockPalette?: number) {
  const defaultPalette = ['var(--primary)', 'var(--secondary)']
  if (!stylesheet?.palettes) return defaultPalette
  if (blockPalette !== undefined)
    return (
      stylesheet.palettes[+blockPalette]?.colors ||
      stylesheet.palettes[+stylesheet.defaultPalette]?.colors ||
      defaultPalette
    )
  if (stylesheet.defaultPalette === undefined) return defaultPalette
  return stylesheet.palettes[stylesheet.defaultPalette]?.colors || defaultPalette
}
function getMaxLimit(limit: number, viz: VizType) {
  if (['pie', 'bar'].includes(viz)) return Math.min(limit || 24, 24)
  return limit
}
function parseOptions(
  data: any[],
  options: ResolvedBuilderOptions,
  context: any,
  defaultOptions?: Partial<INxChartOptions>,
): INxChartOptions {
  const { viz, x, y, unit, digit, limit, other, legend, rebase, notation, sort, sortAsc, category } = options
  const { variables, stylesheet, node, postFormat } = context
  const hasCategory = !!data[0]?.category
  const palette = getStylesheetPalette(stylesheet, node.palette)
  const lang = variables.lang
  const shareCurrency = variables?.shareCurrency
  return {
    viz,
    x,
    y,
    category: category || hasCategory ? 'category' : undefined,
    formatX: formatFactory({ unit, digit, notation, lang, postFormat, shareCurrency }),
    formatY: formatFactory({ unit, digit, notation, lang, postFormat, shareCurrency }),
    formatLabel: formatFactory({ unit, digit, notation, lang, postFormat, shareCurrency }),
    limit: getMaxLimit(limit, defaultOptions?.viz!),
    other,
    legend,
    rebase,
    title: undefined,
    palette,
    sort: null,
    ...defaultOptions,
  }
}

function parseData(data: any[]): any[] {
  if (!data || !data.length) return data
  // if (data[0]?.date && typeof data[0].date === 'string') {
  //   return data.map(d => ({ ...d, date: new Date(d.date) }))
  // }
  return data
}
// Hook used in single builder compoent
export function useBuilderComponent(props: BuilderProps): NxChartProps {
  const { translateData } = useTranslations(props)
  const options = props.data && parseOptions(props.data, props.options, props.context, props.nxChartOptions)
  const data = translateData.value(parseData(props.data))
  const newProps = reactive({ data, options })
  watch(
    () => [props.options, props.nxChartOptions],
    () => {
      console.log('Redrawing options chart')
      newProps.data = translateData.value(parseData(props.data))
      newProps.options = props.data && parseOptions(props.data, props.options, props.context, props.nxChartOptions)
    },
    { deep: true },
  )
  // watch(
  //   () => props.data,
  //   () => {
  //     console.log('Redrawing data chart')
  //     newProps.data = parseData(props.data)
  //   },
  // )
  return newProps
}

// Hook used to get all components and manage them
// Needs to be a hook because config and components are init at runtime
export function useComponents() {
  // @ts-expect-error global stuff
  const components = Object.assign(...window.platform.apps.flatMap(v => v.components)) || {}
  // @ts-expect-error global stuff
  const prefix = config?.prefix || config.defaultTemplate.theme.prefix
  function getComponentTag(componentName: string): string {
    const customComponent = `${prefix}-${componentName}`
    const builderComponent = `builder-${componentName}`
    return components[customComponent] ? customComponent : builderComponent
  }
  function getComponent(componentName: string) {
    const customComponent = `${prefix}-${componentName}`
    const builderComponent = `builder-${componentName}`
    return components[customComponent] || components[builderComponent]
  }
  const allComponents = Object.entries(components).filter(([componentName, component]) => {
    if (componentName.endsWith('-story') || componentName.endsWith('-error')) return false
    return true
  })
  function getComponents() {
    const customComponents = allComponents.filter(([componentName, component]) => {
      return componentName.startsWith(prefix + '-')
    })
    const builderComponents = allComponents.filter(([componentName, component]) => {
      return componentName.startsWith('builder-')
    })
    const _components: Record<string, any> = {}
    builderComponents.forEach(([componentName, component]) => {
      _components[componentName.replace('builder-', '')] = component
    })
    customComponents.forEach(([componentName, component]) => {
      _components[componentName.replace(prefix + '-', '')] = component
    })
    return _components
  }
  function getHeaderComponent(templateHeader?: string) {
    if (!templateHeader) return getComponentTag('header')
    const headerComponent = allComponents.find(([name, component]) => name === templateHeader)
    if (!headerComponent) {
      return getComponentTag('header')
    }
    return headerComponent[0]
  }

  return { getComponentTag, getComponents, getComponent, allComponents, getHeaderComponent }
}

// Non data components
// Data array components
//  - key: string,value: number (categorical)
//  - key: date, value: number (timeseries)
//  - key, value, category (multi category)
// Object component
const vizTypeMapping: Record<string, VizType[]> = {
  CN: ['bar', 'pie', 'graph-table'],
  DN: ['line', 'bar'],
  CNN: ['bar', 'graph-table'],
  DNN: ['line', 'bar', 'area'],
  table: ['table'],
}
export function checkCompatibility(viz: VizType, data: any) {
  if (!data) return true
  if (data.vizType) {
    const availableViz = vizTypeMapping[data.vizType]
    if (!availableViz) return true
    return availableViz.includes(viz)
  }
  if (!Array.isArray(data)) return true
  const firstDatum = data[0]
  if (!firstDatum) return true
  if (firstDatum.key && firstDatum.value) return ['bar', 'pie', 'graph-table'].includes(viz)
  if (firstDatum.date) return ['line', 'area'].includes(viz)
  return true
}
// check if string is a valid date
// function isDateString(date: string) {
//   return new Date(date) && date instanceof Date && !isNaN(date.getTime())
// }

export function getComponentVizType(componentName: string, component: any): VizType {
  return component.viz || componentName.split('-').slice(1).join('-')
}
