<template>
  <div class="template-wrapper !mb-0 flex h-fit flex-col">
    <div v-if="headerData.error">{{ headerData.error }}</div>
    <div class="relative flex items-start">
      <div class="bg-secondary absolute right-0 top-0 h-[1px]" style="width: calc(100% - 20px)"></div>
      <div class="bg-secondary absolute left-[10px] top-0 h-full w-[60px] -skew-x-12"></div>
      <div class="bg-secondary absolute bottom-0 right-0 h-[1px]" style="width: calc(100% - 20px)"></div>
      <div class="text-primary relative ml-[80px] flex w-full flex-col justify-center px-3 py-3">
        <div class="flex text-lg">
          <span v-html="formattedFundName.toUpperCase()"></span>
        </div>

        <div class="flex items-baseline gap-1 text-sm">
          <div class="font-thin">{{ context.t?.monthly_report || '' }}</div>
          <div class="seperator">-</div>
          <div class="font-bold">{{ formattedDate }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import { getHeaderData } from '@100-m/hauru/src/applications/builder/lib/header'
import { dateFormatFactory } from '@100-m/hauru/src/applications/builder/lib/format'

const { context } = defineProps(['context', 'data', 'options'])

const headerData = computed(() => {
  try {
    return getHeaderData(context)
  } catch (e) {
    return { error: e }
  }
})

const formattedDate = computed(() =>
  dateFormatFactory(
    'custom',
    'dd MMMM yyyy',
    context?.variables?.lang?.slice(0, 2),
  )(new Date(headerData.value.endDate)),
)

const formattedFundName = computed(() => {
  const fundName = headerData.value.fundName
  return fundName
})
</script>

<script lang="ts">
export default {
  viz: 'header',
  name: 'Greenrock header 3',
}
</script>
